import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {videosHolder, videoHolder} from './Pages.module.scss'
import {motion} from 'framer-motion'
import Seo from '../components/utilities/Seo'

const Wrap = ({ condition, wrapper, children }) => 
condition ? wrapper(children) : children;


const Video = ({video, image})=> {

  const variants = {
    pre: {
      y: "4rem",
      opacity:0
    },
    vis: {
      y: "0rem",
      opacity:1,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    },
    post: {
      y: "4rem",
      opacity:0,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    }
  }

  return (
    <motion.li variants={variants} className={videoHolder}>
      <Wrap
        condition={video.links}
        wrapper={children => <a href={video.links[0].url} title={video.title} target="_blank" rel="noreferrer" >{children}</a>}
        >
        <GatsbyImage image={getImage(image)} alt={video.title} />
      </Wrap>
      <div className="videoInfo">
        <h3>{video.title}</h3>
        { video.description && <p>{video.description}</p> }
        <ul className="links">
          {
            video.links.map(link=> (
            <li key={link.url}><a href={link.url} target="_blank" rel="noreferrer" title={video.title}>{link.text}</a></li>
            ))
          }
        </ul>
      </div>
    </motion.li>
  )
}




export default function VideosPage({data}) {

  const allVideos = data.allDataJson.nodes[0].videos
  const allImg = data.allFile.nodes


  return (
    <article className={videosHolder}>
      <Seo title="Videos" />
      <section>
        {

          allVideos?.length > 0 ? <ul>
            {
            allVideos.map(video => {
              const image = allImg.find(img=> img.name===video.image)
              return <Video video={video} image={image} key={video.title} /> 
            })
            }
          </ul> :
          <p>No Videos found</p>
        }
      </section> 

    </article>
  )
}

export const query = graphql`
query VideosQuery {
  allDataJson {
    nodes {
      videos {
        description
        image
        title
        links {
          text
          url
        }
      }
    }
  }
  allFile(filter: {relativeDirectory: {eq: "videos"}}) {
    nodes {
      relativeDirectory
      name
      childImageSharp {
        gatsbyImageData(
          width: 840
          height:321
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
}
`